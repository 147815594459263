
import OfferCard from '@/components/offer_card'
import PageNavbar from '@/components/page_navbar'
import LoadingSpinner from '@/components/loading_spinner'
import _ from 'underscore'
import { DateTime } from 'luxon'

export default {
  components: {
    OfferCard,
    LoadingSpinner
  }

  data: ->
    leaflets: []
    loading: false

  computed: {
    offers: ->
      @$store.state.offers.all

    categorized: ->
      grouped = _.groupBy(@offers, 'root_category_name')
      categories = {}
      Object.keys(grouped).sort().forEach (category) ->
        data = grouped[category]
        categories[category] = _.chain(data).sortBy (offer) ->
          offer.sellable.brand
        .sortBy (offer) ->
          offer.sellable.correlations?[0]?.name
        .sortBy (offer) ->
          offer.sellable.product
        .value()
      categories
  }

  mounted: ->
    @loading = true
    @$store.commit('layout/page_nav', {title: @$t('promotions_page_header'), back_target: '/'})
    if @$store.getters['suppliers/current']
      if window.localStorage.getItem('moya-discover')
        @leaflets = []
        @fetch_promotion_offers()
      else
        @$store.dispatch('leaflets/fetch_supplier', @$store.getters['suppliers/current'].id)
        .then (leaflets) => @leaflets = leaflets
        .catch (error) =>
          @leaflets = []
          console.error(error)
        .finally => @fetch_promotion_offers()

  destroyed: ->
    @$store.commit('offers/reset')

  methods: {
    fetch_promotion_offers: ->
      if @$store.getters['suppliers/current']
        @$store.dispatch('offers/fetch_supplier_promotions')
          .then (offers) =>
          .catch (error) => console.error(error)
          .finally => @loading = false

    formatted_date: (date) ->
      DateTime.fromISO(date).toFormat('dd LLL y')

    open_tab: (url) ->
      window.open(url, '_blank')
  }

};
